import { DEFAULT_LANGUAGE } from '@wr/web-shared';
import {
  AppContextProviderShared,
  getSendCountryCodeFromLocale,
} from '@wr/web-ui';
import { GetStaticProps } from 'next';
import { FC } from 'react';

import { Layout } from '@/components/layout';
import { PageMetadataComponent } from '@/components/page-metadata';
import { DEFAULT_404_PAGE_SLUG } from '@/constants';
import { AppContextProvider } from '@/context';
import { getPage, getPageData } from '@/services';
import { PageProps } from '@/types';
import { getAllModuleItems } from '@/utils';
import { resolveComponents } from '@/utils/component-resolver';

import { AppPropsWithLayout } from './_app';

const NotFoundPage: FC<PageProps & AppPropsWithLayout> = ({
  modules,
  originUri,
  regions,
  title,
  description,
  pathname,
  footer,
  header,
  isWebView,
  analyticsPageType,
  noIndex = false,
}) => {
  const resolvedComponents = resolveComponents(modules || []);

  return (
    <AppContextProviderShared
      regions={regions as string[]}
      originUri={originUri}
    >
      <AppContextProvider placeholder="TODO: Add SW specific props here">
        <PageMetadataComponent
          analyticsPageType={analyticsPageType}
          description={description}
          noIndex={noIndex}
          originUri={originUri}
          pathname={pathname}
          title={title}
        />

        <Layout header={isWebView ? {} : header} footer={footer}>
          {resolvedComponents}
        </Layout>
      </AppContextProvider>
    </AppContextProviderShared>
  );
};

export default NotFoundPage;

type NotFoundPage =
  | Pick<
      PageProps,
      | 'name'
      | 'slug'
      | 'title'
      | 'description'
      | 'regions'
      | 'noIndex'
      | 'header'
      | 'footer'
      | 'modules'
      | 'preview'
      | 'pathname'
      | 'originUri'
    >
  | Record<string, never>;

export const getStaticProps: GetStaticProps<
  PageProps | Record<string, unknown>
> = async ({ locale: nextLocale, defaultLocale, preview = false }) => {
  const locale =
    (nextLocale === defaultLocale ? DEFAULT_LANGUAGE : nextLocale) ||
    DEFAULT_LANGUAGE;

  const page = await getPage({
    slug: DEFAULT_404_PAGE_SLUG,
    locale,
    preview,
  });

  if (!page) {
    return {
      props: {},
    };
  }

  const [pageData, modules] = await Promise.all([
    getPageData({
      locale,
      slug: page.slug,
      analyticsPageType: null,
      preview,
      sendCountryCode: getSendCountryCodeFromLocale(locale) || '',
    }),
    getAllModuleItems({
      locale,
      modules: page?.modulesCollection?.items,
      preview,
    }) || null,
  ]);

  const regions =
    page.regions?.filter((region): region is string => Boolean(region)) || [];

  return {
    props: {
      name: page.name,
      slug: page.slug,
      title: page.title,
      description: page.description,
      noIndex: page.noIndex,
      header: {
        ...pageData?.layoutHeaderCollection?.items[0],
        headerPosition: page.headerPosition,
        headerTheme: page.headerTheme,
      },
      footer: page.footer,
      modules,
      preview,
      regions,
      pathname: '/' + DEFAULT_404_PAGE_SLUG,
      originUri: process.env.WEB_CMS_SW_URI,
      analyticsPageType: page.analyticsPageType,
    },
  };
};
